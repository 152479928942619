import React from 'react';
import PropTypes from 'prop-types';

import { MetaTag } from '../../../Helpers/SEO/Tags';

export default function NoIndex({ noFollow, allowRender }) {
  return (
    <>
      {!allowRender &&
        <MetaTag name="prerender-status-code" content="404" />
      }
      <MetaTag name="robots" content={`noindex${noFollow ? ', nofollow' : ''}`} />
    </>
  );
}

NoIndex.propTypes = {
  noFollow: PropTypes.bool,
  allowRender: PropTypes.bool,
};

NoIndex.defaultProps = {
  noFollow: false,
  allowRender: false,
};
