import { v4 as uuidV4 } from 'uuid';
import _get from 'lodash/get';

import { setAnonId } from '../redux/ducks/anonymous';
import store from '../redux/store';
import getUrlParameter from '../Helpers/Url/GetUrlParameter';
import env from '../env';
import waitForSentry from '../Helpers/Sentry/wait-for-sentry';

const ROOT_URL = `${env.apiProtocol}://${env.apiUrl}`;
const BASE_URL = `${env.appProtocol}://${env.appUrl}`;
const { version } = window;

let requests = [];

const hasThrown = {};

/**
 * Special error class for rate limiting, to help sentry grouping
 * @type Error
 */
export class RateLimitError extends Error {
  constructor(message, path) {
    super(message);
    this.path = path;

    if (hasThrown[path]) {
      this.ignore = true;
    } else {
      hasThrown[path] = true;
    }
  }
}

/**
 * Check if request will go over the limit then log request
 * @param  {String} path Request path for logging
 * @throws {Error}
 */
const rateLimiter = (path) => {
  const timestamp = Date.now();
  const threshold = timestamp - (env.ratelimit.interval * 1000);

  requests = requests.filter(r => r.timestamp > threshold);

  const pathRequests = requests.filter(r => r.path === path);

  if (pathRequests.length > env.ratelimit.limit) {
    throw new RateLimitError(`Self rate limit exceeded ${path}`, path);
  }

  requests.push({ timestamp, path });
};

export function getToken() {
  let state = {};
  if (store) { state = store.getState(); }

  const auth = state.auth;

  if (auth) {
    let token;
    try {
      token = auth.token;
    } catch (ex) { /* */ }

    if (token) {
      return token;
    }
  }

  return false;
}

async function getAnonId() {
  let state = {};
  if (store) { state = store.getState(); }

  const anon = state.anonymous;

  if (anon) {
    let anonId;
    try {
      anonId = anon.id;
    } catch (ex) { /* */ }

    if (anonId) return anonId;
  }

  const anonId = uuidV4();
  await setAnonId(anonId);

  return anonId;
}

async function generateHeaders(isContentTypeJson) {
  const headers = new Headers({
    Accept: 'application/json',
  });

  const token = getToken();
  const anonId = await getAnonId();

  if (isContentTypeJson) {
    headers.append('Content-type', 'application/json');
  }

  if (token) headers.append('Authorization', token);
  if (anonId) headers.append('anonid', anonId);

  if (window.version) headers.append('X-Fan-Client-Version', window.version);

  return headers;
}

async function extractData(response) {
  const contentType = response.headers.get('content-type');

  if (contentType && contentType.includes('application/json')) {
    if (response.status === 404) {
      return { response, data: { message: 'Not Found' } };
    }

    return response.json()
      .then(data => ({ response, data }))
      .catch(err => ({ response, data: { err } }));
  }

  return response.text()
    .then(data => ({ response, data }))
    .catch(err => ({ response, data: err }));
}

export function handleResponse(dispatch, response) {
  if (response.status === 204) return false;
  if (response.status === 401) {
    store.dispatch({ type: 'frontend/wishlist/FETCH_WISHLIST', payload: { wishlistLoaded: false } });
    store.dispatch({ type: 'frontend/wishlist/UPDATE_WISHLIST_ITEM', payload: { active: false } });
    store.dispatch({ type: 'frontend/auth/LOGOUT' });
  }

  if (response.status === 429) {
    waitForSentry((Sentry) => {
      Sentry.captureException(new Error('429 response received', { cause: response.url }));
    });
  }

  return extractData(response);
}

/**
 * Do a get request on the relative domain (not the api)
 * @param  {Object} dispatch redux store??
 * @param  {String} path     path and params of url to request
 * @return {Object}
 */
export async function baseHead(dispatch, path) {
  rateLimiter(path);
  const response = await fetch(`${BASE_URL}${path}`, { method: 'HEAD', headers: await generateHeaders() });
  return handleResponse(dispatch, response);
}

/**
 * Check to see if frontend is serving client the newest version
 * Refresh page if it has
 * @param  {Number} newVersion Version that API has told us is latest
 */
async function checkForUpdate(newVersion) {
  const state = store.getState();
  const reviewModalOpen = _get(state, 'modal.frontend/modal/USER_REVIEW_MODAL', false);
  const reviewPage = window.location.pathname.includes('review');
  const paymentPage = window.location.pathname.includes('payment');
  const { response } = await baseHead(null, `${window.location.pathname}${window.location.search}${window.location.search ? '&' : '?'}expectedV=${newVersion}`);
  const newNewVersion = parseInt(response.headers.get('X-Fan-Version'), 10);

  if (newNewVersion >= newVersion && !reviewPage && !reviewModalOpen && !paymentPage) {
    const params = new URLSearchParams(window.location.search);

    if (params.get('v') !== `${newNewVersion}`) {
      params.set('v', newNewVersion);

      window.location.search = `?${params.toString()}`;

      return false;
    }
  }

  return false;
}

export async function get(dispatch, url, skipHeaders = false) {
  rateLimiter(url);
  let newUrl = url;
  const cc = getUrlParameter('cc');

  if (cc) {
    newUrl = `${newUrl}${url.includes('?') ? '&' : '?'}cc=${cc}`;
  }

  const options = {};

  if (!skipHeaders) {
    options.headers = await generateHeaders();
  }

  const response = await fetch(`${ROOT_URL}${newUrl}`, options);

  if (version && response.headers.has('X-Fan-Version')) {
    const newVersion = parseInt(response.headers.get('X-Fan-Version'), 10);
    if (newVersion > version) {
      await checkForUpdate(newVersion);
    }
  }

  return handleResponse(dispatch, response);
}

export async function post(dispatch, url, body) {
  rateLimiter(url);
  const response = await fetch(`${ROOT_URL}${url}`, {
    method: 'POST',
    headers: await generateHeaders(true),
    body: JSON.stringify(body),
  });
  return handleResponse(dispatch, response);
}

export async function del(dispatch, url) {
  rateLimiter(url);
  const response = await fetch(`${ROOT_URL}${url}`, {
    method: 'DELETE',
    headers: await generateHeaders(true),
  });
  return handleResponse(dispatch, response);
}

export async function put(dispatch, url, body) {
  rateLimiter(url);
  const response = await fetch(`${ROOT_URL}${url}`, {
    method: 'PUT',
    headers: await generateHeaders(true),
    body: JSON.stringify(body),
  });
  return handleResponse(dispatch, response);
}

export async function patch(dispatch, url, body) {
  rateLimiter(url);
  const response = await fetch(`${ROOT_URL}${url}`, {
    method: 'PATCH',
    headers: await generateHeaders(true),
    body: JSON.stringify(body),
  });
  return handleResponse(dispatch, response);
}

// suppress defaultProps warning in development - https://react.dev/blog/2024/04/25/react-19-upgrade-guide#removed-proptypes-and-defaultprops
if (env.isDevelopment) {
  const originalConsoleError = console.error; // eslint-disable-line no-console

  console.error = (...args) => { // eslint-disable-line no-console
    if (args[0] && typeof args[0] === 'string' && args[0].includes('Support for defaultProps will be removed')) {
      return;
    }
    originalConsoleError(...args);
  };
}
