import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import usePrevious from '../../hooks/usePrevious';

/**
 * If the pathname or the page query param changes scroll to top of page
 */
function ScrollToTop() {
  const { pathname, search, hash } = useLocation();

  const previousPathname = usePrevious(pathname);
  const previousSearch = usePrevious(search);

  useLayoutEffect(() => {
    const pathDiff = pathname !== previousPathname;
    const pageDiff = new URLSearchParams(search).get('page') !== new URLSearchParams(previousSearch).get('page');

    if (pathDiff || pageDiff) {
      const hashId = hash.replace('#', '');

      if (hashId && document.getElementById(hashId)) {
        window.scrollTo({
          behavior: 'auto',
          top:
            document.getElementById(hashId).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            80,
        });
      } else if (document.documentElement && document.documentElement.scrollTo) {
        document.documentElement.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [pathname, search]); // eslint-disable-line react-hooks/exhaustive-deps

  return false;
}

export default ScrollToTop;
