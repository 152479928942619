/**
 * Split tests to use in the app
 * Can be passed as a string (name) or an object with configuration
 * See defaults in split-test duck for default variants and other available options
 * @type {String|Array.Object}
 */

module.exports = [
  'CartUndoWithDelay', // Test 386
  'CategoryPageFourColumns', // Test 402
  'CleanNavPayment', // Test 404
  'BundleDetailsMetacritic', // Test 405
  'PnmWishlistPriority', // Test 375
  'MysteryKeySelection', // Test 412
  'LunarSaleTakeover', // Test 410
  'DesktopDLCCarousel', // Test 409
  'BundlesSortAndFilter', // Test 413
  'HomePageTrustBannerMobile', // Test 420
];
