import { useMeta, useLink, useTitle, useScript } from 'hoofd';

export function MetaTag(props) {
  useMeta(props);
}

export function LinkTag(props) {
  useLink({ ...props, 'data-hoofd': true });
}

export function TitleTag(props) {
  useTitle(props.children);
}

export function ScriptTag(props) {
  useScript({ ...props, text: props.script });
}
