import React, { lazy, useEffect, useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { getByCode, publicFormat, findBrowserLang, getAll } from '../../config/lang';
import { getDefaultCurrency, getNativeCurrency, isCurrencySelected, hasStoredLanguage, getStoredCurrency } from '../../Helpers/Localisation/Localisation';
import { toggleLanguageModal } from '../../redux/ducks/modal';
import '../Languages/BrowserLang.scss';
import isBot from '../../Helpers/SEO/is-bot';
import LazyModal from '../../components/Utility/LazyModal';
import { getNativeCurrencyRate } from '../../redux/ducks/native-rates';

const LanguagePickerModal = lazy(() => import(/* webpackChunkName: "LanguagePickerModal" */'../../components/Utility/LanguagePickerModal'));

const LocalisationPrompt = () => {
  const [display, setDisplay] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const showPrompt = () => {
      const matched = findBrowserLang();
      const current = window.locale;
      dispatch({
        type: 'frontend/languages/SHOW_PROMPT',
        payload: {
          context: 'banner',
          from: current,
          to: matched,
        },
      });
    };

    if (getAll().length >= 2) showPrompt();
  }, [dispatch]);

  if (getAll().length < 2) return false;

  if (hasStoredLanguage() && getStoredCurrency()) return false;

  const matched = findBrowserLang();
  const current = window.locale;
  const { bannerTranslations } = window;

  const matchedCurrency = getNativeCurrency();
  const currencySelected = isCurrencySelected();

  const hasMatchedLang = matched && matched !== current && !hasStoredLanguage();

  const matchedLang = hasMatchedLang ? publicFormat(getByCode(matched)) : null;
  const currentLang = publicFormat(getByCode(current));


  const showCurrency = !hasMatchedLang && !!matchedCurrency && !currencySelected;
  const showLanguage = hasMatchedLang && (!matchedCurrency || currencySelected);
  const showBoth = hasMatchedLang && !!matchedCurrency && !currencySelected;

  const show = !isBot() && display && (showCurrency || showLanguage || showBoth);
  if (!show) return false;

  const handleClose = (e) => {
    if (e) e.preventDefault();
    setOpenModal(false);
    dispatch(toggleLanguageModal());
    setDisplay(false);
  };

  const handleModal = (e) => {
    if (e) e.preventDefault();
    setOpenModal(prevState => !prevState);
    dispatch(toggleLanguageModal());
  };

  /**
   * Save the user's language and redirect or hide banner if necessary
   * @param {Object}  language language object
   */
  const setLanguage = (language) => {
    localStorage.setItem('language', JSON.stringify(language));

    const redirect = language.code !== window.locale;

    if (redirect) {
      dispatch({
        type: 'frontend/languages/CHANGE_LANGUAGE',
        payload: {
          context: 'banner',
          from: window.locale,
          to: language.code,
        },
      });

      const rx = new RegExp(window.locale);
      const path = window.location.pathname.replace(rx, language.code);
      window.location.href = window.location.origin + path + window.location.search;
    } else {
      setDisplay(false);
    }
  };

  const setCurrency = (code) => {
    localStorage.setItem('displayCurrency', code);
    dispatch(getNativeCurrencyRate());
    setDisplay(false);
  };

  const defaultCurrency = getDefaultCurrency();

  return (
    <div className="lang-outer new">
      <div className="lang-text-buttons-container container">
        <IntlProvider locale={matched} messages={bannerTranslations[matched]}>
          {showCurrency &&
          <React.Fragment>
            <div className="text-container">
              <FormattedMessage
                id="browse-lang.fanatical-message-only-currency"
                defaultMessage="Would you like to see Fanatical prices in approximate {newCurrencyName} ({newCurrencyCode})?"
                values={{
                  newCurrencyName: matchedCurrency.nativeCurrencyName,
                  newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                }}
              />
            </div>

            <div className="buttons-container">
              <div className="button-container">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => setCurrency(matchedCurrency.nativeCurrencyCode)}
                >
                  <FormattedMessage
                    id="browse-lang.change-to-only-currency"
                    defaultMessage="Change to {newCurrencyCode}"
                    values={{
                      newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                    }}
                  />
                </button>
              </div>

              <div className="button-container">
                <button className="btn btn-sm btn-secondary" onClick={() => setCurrency(defaultCurrency.currencyCode)}>
                  <FormattedMessage
                    id="browse-lang.change-site-only-currency"
                    defaultMessage="Stay in {currentCurrencyCode}"
                    values={{
                      currentCurrencyCode: defaultCurrency.currencyCode,
                    }}
                  />
                </button>
              </div>

              <div className="button-container">
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={handleModal}
                >
                  <FontAwesomeIcon icon="cog" />
                </button>
              </div>
            </div>
          </React.Fragment>
            }

          {showLanguage &&
          <React.Fragment>
            <div className="text-container">
              <FormattedMessage
                id="browse-lang.fanatical-message"
                defaultMessage="Would you like to see Fanatical in {newLanguageLabel}?"
                values={{
                  newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                }}
              />
            </div>

            <div className="buttons-container">
              <div className="button-container">
                <button className="btn btn-sm btn-secondary" onClick={() => setLanguage(matchedLang)}>
                  <FormattedMessage
                    id="browse-lang.change-to"
                    defaultMessage="Change to {newLanguageLabel}"
                    values={{
                      newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                    }}
                  />
                </button>
              </div>

              <div className="button-container">
                <button className="btn btn-sm btn-secondary" onClick={() => setLanguage(currentLang)}>
                  <FormattedMessage
                    id="browse-lang.change-site"
                    defaultMessage="Stay in {currentLanguageLabel}"
                    values={{
                      currentLanguageLabel: currentLang.nativeLabel,
                    }}
                  />
                </button>
              </div>
            </div>
          </React.Fragment>
            }

          {showBoth &&
          <React.Fragment>
            <div className="text-container">
              <FormattedMessage
                id="browse-lang.fanatical-message-with-currency"
                defaultMessage="Would you like to see Fanatical in {newLanguageLabel} and approximate {newCurrencyName} ({newCurrencyCode})?"
                values={{
                  newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                  newCurrencyName: matchedCurrency.nativeCurrencyName,
                  newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                }}
              />
            </div>

            <div className="buttons-container">
              <div className="button-container">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    setCurrency(matchedCurrency.nativeCurrencyCode);
                    setLanguage(matchedLang);
                  }}
                >
                  <FormattedMessage
                    id="browse-lang.change-to-with-currency"
                    defaultMessage="Change to {newLanguageLabel} and {newCurrencyCode}"
                    values={{
                      newLanguageLabel: matchedLang && matchedLang.nativeLabel,
                      newCurrencyCode: matchedCurrency.nativeCurrencyCode,
                    }}
                  />
                </button>
              </div>

              <div className="button-container">
                <button
                  className="btn btn-sm btn-secondary"
                  onClick={() => {
                    setCurrency(defaultCurrency.currencyCode);
                    setLanguage(currentLang);
                  }}
                >
                  <FormattedMessage
                    id="browse-lang.change-site-with-currency"
                    defaultMessage="Stay in {currentLanguageLabel} and {currentCurrencyCode}"
                    values={{
                      currentLanguageLabel: currentLang.nativeLabel,
                      currentCurrencyCode: defaultCurrency.currencyCode,
                    }}
                  />
                </button>
              </div>

              <div className="button-container">
                <button className="btn btn-sm btn-outline-secondary" onClick={handleModal}>
                  <FontAwesomeIcon icon="cog" />
                </button>
              </div>
            </div>
          </React.Fragment>
            }
        </IntlProvider>
      </div>
      <LazyModal
        open={openModal}
        setOpen={handleClose}
        className="account-modal"
      >
        <LanguagePickerModal />
      </LazyModal>
    </div>
  );
};

export default LocalisationPrompt;
